<template>
  <v-dialog :value="dialog" v-if="dialog" persistent max-width="580px">
    <v-card>
      <v-card-title class="confirm_headline"
        ><br />{{ dialogHeadline
        }}<v-icon class="icon_position" @click="$emit('close')"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" dark dense @click="$emit('close')">{{
          $t("confirmationDialog.cancel")
        }}</v-btn>
        <v-btn color="primary" dark dense @click="$emit('confirm')">{{
          $t("confirmationDialog.ok")
        }}</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
      <br />
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmationDialog",

  props: {
    dialog: {
      type: Boolean,
      default: true,
    },
    dialogHeadline: {
      type: String,
      default: "",
    },
  },

  computed: {
    dialogStatus: {
      get: function () {
        return this.dialog;
      },
      set: function () {},
    },
  },
};
</script>
